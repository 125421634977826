import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Col, Row } from "react-bootstrap";
import "./SectionHeader.scss";

const SectionHeader = ({ header, subheader, className, ...restProps }) => {
  const subheaderPart = subheader ? <h3 className="heading">{subheader}</h3> : null;

  return (
    <div className={clsx("section__header", "text-center", className)} {...restProps}>
      <h2>{header}</h2>
      <p>{subheader}</p>
    </div>
  );
};

SectionHeader.propTypes = {
  header: PropTypes.string,
  subheader: PropTypes.string,
  className: PropTypes.string,
};

SectionHeader.defaultProps = {
  header: "",
  subheader: "",
  className: null,
};

export default SectionHeader;
