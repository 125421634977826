import React from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";
import Image from "components/Image";
import "./StatsItem.scss";
import { Col, Card, Badge } from "react-bootstrap";
import Arrow from "content/assets/svg/up-arrow.svg";
// import Chart from "content/assets/svg/growth-state.svg";

const StatsItem = ({ iconName, imageFileName, header, content, index, growth, text }) => {
  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="service-item-image" fileName={imageFileName} />;
  }

  return (
    <>
      <Col md={4} sm={6} className="stats-item__col">
        {/* <h3>{statement.header}</h3> */}
        <Card className="shadow-lg">
          <Card.Body>
            <Card.Title className="d-flex align-items-center justify-content-between">
              <h3 className="section-sub mb-0">{header}</h3>
              <Badge className="section-sub">
                UP {growth} <Arrow />
              </Badge>
            </Card.Title>
            <Card.Text className="h1">{content}</Card.Text>
            <Card.Text className="font-mono stats-item__footer">{text}</Card.Text>
          </Card.Body>
        </Card>
      </Col>
      {/* {iconPart}
      {imagePart} */}
      {/* <div className="service-item">
        <h3 className="service-item-heading">{header}</h3>
        <p className="text-muted">{content}</p>
      </div> */}
    </>
  );
};

StatsItem.propTypes = {
  iconName: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  index: PropTypes.string,
  growth: PropTypes.string,
  text: PropTypes.string,
};

StatsItem.defaultProps = {
  iconName: null,
  imageFileName: null,
  header: "",
  content: "",
  index: "",
  growth: "",
  text: "",
};

export default StatsItem;
