import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import StatsItem from "components/StatsItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Stats.scss";
import Image from "components/Image";

const Stats = ({ className, frontmatter }) => {
  const contentRef = useRef();
  const [height, setHeight] = useState();

  const getHeight = () => {
    const newHeight = contentRef.current.clientHeight;
    setHeight(newHeight);
  };

  useEffect(() => {
    getHeight();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getHeight);
  }, []);

  const bgHeight = {
    // height: { height } + "px",
    height: "calc(" + String(height) + "px + 30vh )",
    // height: "auto",
  };

  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    stats,
    imageFileName: rootImageFileName,
    imageAlt: rootImageAlt,
  } = frontmatter;

  return (
    <div className={className + " stats"} id={anchor} style={bgHeight}>
      <Image
        className="stats__img rounded-lg w-100"
        fileName={rootImageFileName}
        alt={rootImageAlt || rootHeader || rootSubHeader}
      />

      <div className="stats__content container" ref={contentRef}>
        <div className="section">
          <div className="section__header stats__header">
            <h2 className="">{rootHeader}</h2>
            <p className="">{rootSubHeader}</p>
          </div>
        </div>
        <Row>
          {stats.map((stat) => (
            <StatsItem {...stat} key={stat.header} />
          ))}
        </Row>
      </div>
    </div>
  );
};

Stats.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Stats.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Stats;
