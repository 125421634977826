import React from "react";
import PropTypes from "prop-types";

import "./MapItem.scss";
import Pin from "content/assets/svg/MapPin.svg";

const MapItem = ({ header, color, locations }) => {
  console.log("locations:", locations);

  return (
    <div className={"map-state map-state__" + header.split(" ").join("-").toLowerCase()}>
      <div className="map-state__wrap ">
        <span className="map-state__title">{header}</span>

        {!locations
          ? null
          : locations.map(({ locationId, locationColor }) => {
              return (
                <div
                  key={locationId}
                  className={
                    "map-state__item map-state__" +
                    locationId
                      .split(" ")
                      .join("-")
                      .substring(0, locationId.indexOf("--"))
                      .toLowerCase() +
                    "-item--" +
                    locationId.split(" ").join("-").toLowerCase()
                  }
                >
                  {/* <Pin
                    className={
                      "map-state__pin map-state__pin--" +
                      locationColor +
                      " map-state__pin--" +
                      locationId.split(" ").join("-").toLowerCase()
                    }
                    /> */}
                  <div
                    className={
                      "map-state__pin map-state__pin--" +
                      locationColor +
                      " map-state__pin--" +
                      locationId.split(" ").join("-").toLowerCase()
                    }
                  >
                    <div className={"dot dot--" + locationColor} />
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

MapItem.propTypes = {
  header: PropTypes.string.isRequired,
  color: PropTypes.string,
  locations: PropTypes.array,
  // locations: PropTypes.shape({
  //   locationId: PropTypes.string,
  //   locationColor: PropTypes.string,
  // }),
};

MapItem.defaultProps = {
  color: "",
  locations: "",
  // locations: PropTypes.shape({
  //   locationId: null,
  //   locationColor: null,
  // }),
};

export default MapItem;
