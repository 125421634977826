import React, { useState } from "react";
import PropTypes from "prop-types";

import { Row, Col, Button, Card } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import "./Contact.scss";

import ContactFormModal from "components/ContactFormModal";

const Contact = ({ className, frontmatter }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, telephone, email, buttonLabel } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      {/* <Row>
        <Button size="lg" variant="primary" className="d-block m-auto" onClick={handleShow}>
          {buttonLabel}
        </Button>
        <ContactFormModal show={show} onHide={handleClose} buttonLabel={buttonLabel} />
      </Row> */}

      <Row>
        <Col lg={4} className="mr-auto text-center">
          <Card className="contact-form__card">
            <Card.Body>
              <h3 className="section-sub">We`d love to hear from you!</h3>
              <Button size="lg" variant="primary" className="d-block m-auto" onClick={handleShow}>
                {buttonLabel}
              </Button>
              <ContactFormModal show={show} onHide={handleClose} buttonLabel={buttonLabel} />
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} className="ml-auto text-center">
          <Card className="contact-form__card">
            <Card.Body>
              <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
              <a className="d-block" href={`mailto:${email}`}>
                {email}
              </a>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4} className="mr-auto text-center">
          <Card className="contact-form__card">
            <Card.Body>
              <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
              <a className="d-block" href={`tel:${telephone}`}>
                {telephone}
              </a>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
