import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Card, Accordion, Button } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
// import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Services.scss";
import Image from "components/Image";
// import Video from "content/assets/images/values/values.mp4";

const Services = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    services,
    imageFileName: rootImageFileName,
    imageAlt: rootImageAlt,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor} style={{ backgroundColor: "#f2f2f2" }}>
      <div className="values">
        <div className="values__header section__header">
          <h2>{rootHeader}</h2>
          <p>{rootSubHeader}</p>
        </div>

        {/* <div className="row">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=tAvp5Gqu4YU"
            style={{ margin: "auto" }}
          />
        </div> */}

        <div className="row">
          {services.map((service, index) => (
            <Col lg={4} key={service.header} className="services__col">
              <ServiceItem {...service} index={index} />
            </Col>
          ))}
          {/* <ServiceItem {...service} className="col-lg-4" key={service.header} index={index} /> */}
        </div>
      </div>
    </PageSection>
  );
};

Services.propTypes = {
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Services.defaultProps = {
  imageFileName: "",
  imageAlt: null,
  className: null,
  frontmatter: null,
};

export default Services;
