import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import "./Facilities.scss";
import Map from "components/Map";
import MapLegend from "components/MapLegend";

const Facilities = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, facilities, legend } = frontmatter;

  return (
    <PageSection className={clsx("facilities-section", className)} id={anchor}>
      <div>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </div>
      <Row>
        <MapLegend legend={legend} />
      </Row>
      <Row>
        <Map facilities={facilities} />
      </Row>
    </PageSection>
  );
};

Facilities.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Facilities.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Facilities;
