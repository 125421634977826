import React, { useContext } from "react";
import PropTypes from "prop-types";
import AccordionContext from "react-bootstrap/AccordionContext";
import { Card, Accordion } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
// import classNames from "classnames";

function ServiceItemToggle({ header, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Accordion.Toggle
      as={Card.Header}
      // style={{ backgroundColor: isCurrentEventKey ? "pink" : "lavender" }}
      onClick={decoratedOnClick}
      // className="accordion__toggle"
      className={isCurrentEventKey ? "accordion__toggle is-active" : "accordion__toggle"}
    >
      <h3>{header}</h3>
      <svg
        className="accordion__toggle-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z" />
      </svg>
    </Accordion.Toggle>
  );
}

ServiceItemToggle.propTypes = {
  header: PropTypes.string,
  eventKey: PropTypes.string,
  callback: PropTypes.string,
};

ServiceItemToggle.defaultProps = {
  header: "",
  eventKey: "",
  callback: "",
};

export default ServiceItemToggle;
