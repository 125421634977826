import React, { useState } from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import * as SocialIcons from "components/SocialIcons";
import { Col, Card, Button } from "react-bootstrap";
import "./TeamMember.scss";
import TeamMemberModal from "components/TeamMemberModal";

const TeamMember = ({
  imageFileName,
  imageAlt,
  header,
  subheader,
  bio,
  social: { twitter, facebook, linkedin, github, medium },
}) => {
  const twitterPart = twitter ? <SocialIcons.Twitter userName={twitter} /> : null;
  const facebookPart = facebook ? <SocialIcons.Facebook userName={facebook} /> : null;
  const linkedinPart = linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null;
  const githubPart = github ? <SocialIcons.Github userName={github} /> : null;
  const mediumPart = medium ? <SocialIcons.Medium userName={medium} /> : null;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const bioPart = bio ? (
    <Button size="sm" variant="link" className="" onClick={handleShow}>
      Read more
    </Button>
  ) : null;

  return (
    <Col className="mb-4">
      <Card>
        <Card.Body>
          <Card.Title className="section-sub">{header}</Card.Title>
          <p className="card-sub">{subheader}</p>
          {bioPart}

          <TeamMemberModal
            className="team-member-modal__dialog"
            header={header}
            subheader={subheader}
            social={(twitter, facebook, linkedin, github, medium)}
            show={show}
            onHide={handleClose}
            bio={bio}
            imageFileName={imageFileName}
            imageAlt={imageAlt || header || subheader}
          />

          <div className="card-socials">
            {linkedinPart}
            {twitterPart}
          </div>
        </Card.Body>
        <Image
          className="mx-auto circle rounded-circle"
          fileName={imageFileName}
          alt={imageAlt || header || subheader}
        />
      </Card>
    </Col>

    // <div className="team-member">
    //   <Image
    //     className="mx-auto circle rounded-circle"
    //     fileName={imageFileName}
    //     alt={imageAlt || header || subheader}
    //   />
    //   <h4>{header}</h4>
    //   <p className="text-muted">{subheader}</p>
    //   <div>
    //     {twitterPart}
    //     {facebookPart}
    //     {linkedinPart}
    //     {githubPart}
    //     {mediumPart}
    //   </div>
    // </div>
  );
};

TeamMember.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  bio: PropTypes.string,
  social: PropTypes.shape({
    twitter: PropTypes.string,
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
    github: PropTypes.string,
    medium: PropTypes.string,
  }),
};

TeamMember.defaultProps = {
  imageAlt: null,
  header: "",
  subheader: "",
  bio: "",
  social: {
    twitter: null,
    facebook: null,
    linkedin: null,
    github: null,
    medium: null,
  },
};

export default TeamMember;
