import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import { Link } from "gatsby";
import ReactPlayer from "react-player";

const About = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center align-items-center">
        <Col lg={6} className="text-center">
          <div className="values__header section__header">
            <h2>{header}</h2>
            <p>{subheader}</p>
          </div>
        </Col>
        <Col lg={6} className="text-center">
          <div className="player-wrapper">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=tAvp5Gqu4YU"
              width="100%"
              height="100%"
              className="react-player"
            />
          </div>
        </Col>
      </Row>
      {/* <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=tAvp5Gqu4YU"
            style={{ margin: "auto" }}
          />
        </Col>
      </Row> */}
    </PageSection>
  );
};

About.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  // allGreenhouseJob: PropTypes.object,
};

About.defaultProps = {
  className: null,
  frontmatter: null,
  // allGreenhouseJob: null,
};

export default About;
