import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import "./TeamMemberModal.scss";
import ContactForm from "./ContactForm";

const ContactFormModal = ({ show, onHide }) => {
  return (
    <Modal size="lg" dialogClassName="team-member-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <h2>Contact us</h2>
        <ContactForm />
      </Modal.Body>
    </Modal>
  );
};

ContactFormModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

ContactFormModal.defaultProps = {
  show: false,
  onHide: false,
};

export default ContactFormModal;
