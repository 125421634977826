import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import Image from "components/Image";
import "./TeamMemberModal.scss";
import * as SocialIcons from "components/SocialIcons";

const TeamMemberModal = ({
  header,
  subheader,
  className,
  show,
  onHide,
  bio,
  imageFileName,
  imageAlt,
  twitter,
  facebook,
  linkedin,
  github,
  medium,
}) => {
  const twitterPart = twitter ? <SocialIcons.Twitter userName={twitter} /> : null;
  const facebookPart = facebook ? <SocialIcons.Facebook userName={facebook} /> : null;
  const linkedinPart = linkedin ? <SocialIcons.Linkedin userName={linkedin} /> : null;
  const githubPart = github ? <SocialIcons.Github userName={github} /> : null;
  const mediumPart = medium ? <SocialIcons.Medium userName={medium} /> : null;
  return (
    <Modal size="lg" dialogClassName="team-member-modal" show={show} onHide={onHide} centered>
      <Modal.Header closeButton />
      <Modal.Body className="team-member-modal__body">
        <div className="team-member-modal__left">
          <Image
            className="mx-auto circle rounded-circle"
            fileName={imageFileName}
            alt={imageAlt || header || subheader}
          />
          <div className="team-member-modal__social">
            {linkedinPart}
            {twitterPart}
          </div>
        </div>

        <div className="team-member-modal__right">
          <h4>{header}</h4>
          <p>{subheader}</p>
          <p>{bio}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

TeamMemberModal.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  bio: PropTypes.string,

  twitter: PropTypes.string,
  facebook: PropTypes.string,
  linkedin: PropTypes.string,
  github: PropTypes.string,
  medium: PropTypes.string,

  className: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

TeamMemberModal.defaultProps = {
  imageAlt: null,
  header: "",
  subheader: "",
  bio: "",

  twitter: null,
  facebook: null,
  linkedin: null,
  github: null,
  medium: null,

  className: "",
  show: false,
  onHide: false,
};

export default TeamMemberModal;
