import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, ListGroup } from "react-bootstrap";
import Icon from "components/Icon";
import PageSection from "components/PageSection";
import { Link, useStaticQuery, graphql } from "gatsby";

const Careers = ({ className, frontmatter }) => {
  const greenhouse = useStaticQuery(graphql`
    query GreenhouseQuery {
      allGreenhouseJob {
        nodes {
          title
          location {
            name
          }
          departments {
            name
          }
          absolute_url
          id
        }
      }
    }
  `);

  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, telephone, email, buttonLabel } = frontmatter;

  console.log("greenhouse", greenhouse);

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <hr className="divider my-4" />
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <ListGroup className="">
            {greenhouse.allGreenhouseJob.nodes.length &&
              greenhouse.allGreenhouseJob.nodes.map((job, index) => (
                <ListGroup.Item key={job.id}>
                  <p style={{ marginBottom: "4px" }}>{job.location.name}</p>
                  <h5 style={{ marginBottom: "8px" }}>{job.title}</h5>
                  <Link to={job.absolute_url} className>
                    <Button variant="primary">View job</Button>
                  </Link>
                </ListGroup.Item>
              ))}
          </ListGroup>
        </Col>
      </Row>
    </PageSection>
  );
};

Careers.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
  // allGreenhouseJob: PropTypes.object,
};

Careers.defaultProps = {
  className: null,
  frontmatter: null,
  // allGreenhouseJob: null,
};

export default Careers;
