import React from "react";
import PropTypes from "prop-types";
// import { Col } from "react-bootstrap";
import "./Map.scss";
import USAMap from "content/assets/svg/usaMap.svg";
import MapItem from "components/MapItem";

const Map = ({ facilities }) => {
  return (
    <div className="map">
      <USAMap className="map-svg" />
      {facilities.map(
        ({ content, locations, header, imageFileName, imageFileNameDetail, subheader, color }) => (
          <MapItem
            key={header}
            imageFileName={imageFileName}
            header={header}
            subheader={subheader}
            content={content}
            color={color}
            imageFileNameDetail={imageFileNameDetail}
            locations={locations}
          />
        ),
      )}
    </div>
  );
};

Map.propTypes = {
  facilities: PropTypes.array,
};

Map.defaultProps = {
  facilities: null,
};

export default Map;
