import React, { useState } from "react";
import PropTypes from "prop-types";

import CircleFAButton from "components/CircleFAButton";
import Image from "components/Image";
import "./ServiceItem.scss";
import { Card, Accordion } from "react-bootstrap";
import ServiceItemToggle from "components/ServiceItemToggle";

const ServiceItem = ({ iconName, imageFileName, header, content, index, className }) => {
  let iconPart;
  if (iconName) {
    iconPart = <CircleFAButton iconName={iconName} />;
  }

  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="service-item-image" fileName={imageFileName} />;
  }

  return (
    <Card>
      <Image
        fileName={imageFileName}
        className="card-img-top service-item__image-wrapper"
        aspectratio={4 / 3}
        alt={"WAHA Technologies - " + header}
      />
      <Card.Body>
        <Card.Title>{header}</Card.Title>
        <Card.Text>{content}</Card.Text>
      </Card.Body>
    </Card>
  );
};

ServiceItem.propTypes = {
  iconName: PropTypes.string,
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  content: PropTypes.string,
  index: PropTypes.number,
  className: PropTypes.string,
};

ServiceItem.defaultProps = {
  iconName: null,
  imageFileName: null,
  header: "",
  content: "",
  index: "",
  className: "",
};

export default ServiceItem;
