import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import "./IntegrationItem.scss";
import { ListGroup, Card } from "react-bootstrap";
import Arrow from "content/assets/svg/integrationArrow.svg";

const IntegrationItem = ({
  imageFileName,
  header,
  index,
  arr,
  linesHeading,
  lines: { one, two, three, four },
}) => {
  let lineOnePart;
  if (one) {
    lineOnePart = <li>{one}</li>;
  }
  let lineTwoPart;
  if (two) {
    lineTwoPart = <li>{two}</li>;
  }
  let lineThreePart;
  if (three) {
    lineThreePart = <li>{three}</li>;
  }
  let lineFourPart;
  if (four) {
    lineFourPart = <li>{four}</li>;
  }
  let imagePart;
  if (imageFileName) {
    imagePart = <Image className="integration__img" fileName={imageFileName} />;
  }

  let headerPart;
  if (linesHeading) {
    headerPart = (
      <Card.Header className="integration-arrow__wrap integration-arrow__wrap--shade">
        <Arrow />
        <h3 className="section-sub">{linesHeading}</h3>
        <Arrow />
      </Card.Header>
    );
  }

  return (
    <>
      <Card key={header} className="integration-item__card rounded-0 border-0">
        {headerPart}
        {imagePart}
        <Card.Body className={imagePart ? "integration-item__overlay" : ""}>
          <h3 className="h5 bold mb-0">{header}</h3>
          {lineTwoPart}
          {lineOnePart}
          {lineThreePart}
          {lineFourPart}
        </Card.Body>
      </Card>

      {/* {index !== arr.length - 1 ? (
        <>
          <ListGroup.Item key={header}>
            <h3 className="h5 bold mb-0">{header}</h3>
            {lineTwoPart}
            {lineOnePart}
            {lineThreePart}
            {lineFourPart}
          </ListGroup.Item>
          <ListGroup.Item className="integration-arrow__wrap integration-arrow__wrap--shade">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="integration-arrow"
            >
              <path d="M17.554 12l-6 6h-3.979l3.093-3h-10.668v-6h10.668l-3.093-3h3.979l6 6zm.446-6h-3.979l6 6-6 6h3.979l6-6-6-6z" />
            </svg>
          </ListGroup.Item>
        </>
      ) : (
        <ListGroup.Item className="p-0 integration-item__text-overlay" key={header}>
          {imagePart}
          <div className="integration-item__content-box">
            <h3 className="h5 mb-0">{header}</h3>
          </div>
        </ListGroup.Item>
      )} */}
      <div />
    </>
  );
};

IntegrationItem.propTypes = {
  imageFileName: PropTypes.string,
  header: PropTypes.string,
  linesHeading: PropTypes.string,
  lines: PropTypes.shape({
    one: PropTypes.string,
    two: PropTypes.string,
    three: PropTypes.string,
    four: PropTypes.string,
  }),
  index: PropTypes.number,
  arr: PropTypes.array,
};

IntegrationItem.defaultProps = {
  imageFileName: null,
  header: "",
  linesHeading: "",
  lines: PropTypes.shape({
    one: null,
    two: null,
    three: null,
    four: null,
  }),
  index: "",
  arr: null,
};

export default IntegrationItem;
