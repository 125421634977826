import React, { useState } from "react";
import PropTypes from "prop-types";
// import { Col, ListGroup } from "react-bootstrap";
import "./MapLegend.scss";
import Pin from "content/assets/svg/MapPin.svg";

const MapLegend = ({ legend }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  // const toggleHover = (index) => {
  //   setHovered((state) => ({
  //     ...state,
  //     [index]: !state[index],
  //   }));
  // };

  return (
    <div className="map-legend mx-auto">
      {legend.map(({ color, text, index }) => (
        <div
          key={color}
          className={activeIndex === index ? "map-legend__item is-active" : "map-legend__item"}
          // onMouseEnter={toggleHover(index)}
          // onMouseLeave={toggleHover(index)}
        >
          <Pin className={"map-legend__pin map-legend__pin--" + color} />
          <span className="section-sub">{text}</span>
        </div>
      ))}
    </div>
  );
};

MapLegend.propTypes = {
  legend: PropTypes.array,
};

MapLegend.defaultProps = {
  legend: null,
};

export default MapLegend;
