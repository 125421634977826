import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Row, Col, Card, ListGroup } from "react-bootstrap";

import IntegrationItem from "components/IntegrationItem";
import SplitText from "components/SplitText";
import PageSection from "components/PageSection";
import "./Integration.scss";
// import Image from "components/Image";
import Bitcoin from "content/assets/svg/bitcoin.svg";

const Integration = ({ className, frontmatter }) => {
  // const rotateTextRef = useRef();

  // useEffect(() => {
  //   // rotateTextRef.replace(/\S/g, "<span>$&</span>");
  //   const textStr = rotateTextRef.current.textContent;
  //   textStr.replace(/\S/g, "<span>$&</span>");
  //   console.log("textcontent", textStr);
  // });

  if (!frontmatter) {
    return null;
  }

  const {
    anchor,
    header: rootHeader,
    subheader: rootSubHeader,
    integrations,
    imageFileName: rootImageFileName,
    imageAlt: rootImageAlt,
  } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <div className="values">
        <div className="values__header section__header">
          <h2>{rootHeader}</h2>
          <p>{rootSubHeader}</p>
        </div>

        <div className="col-md-6 offset-md-3">
          <ListGroup className="mx-auto shadow-lg overflow-hidden">
            {integrations.map((integration, index, arr) => (
              <IntegrationItem {...integration} index={index} arr={arr} key={integration.header} />
            ))}
          </ListGroup>
        </div>
        <div className="bitcoin-circle d-flex flex-column align-items-center justify-content-center position-relative mt-5">
          <Bitcoin className="icon-bitcoin" />
          <h4>A Secure Global Financial Asset</h4>
          {/* <h3 className="section-sub bitcoin-circle__text" ref={rotateTextRef}>
            A Secure Global Financial Asset
          </h3> */}
          {/* <SplitText className="circle" text="A Secure Global Financial Asset" /> */}
        </div>
      </div>
    </PageSection>
  );
};

Integration.propTypes = {
  imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Integration.defaultProps = {
  imageFileName: null,
  imageAlt: null,
  className: null,
  frontmatter: null,
};

export default Integration;
