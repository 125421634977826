import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./SplitText.scss";
import Bitcoin from "content/assets/svg/bitcoin.svg";

const SplitText = ({ text, className }) => {
  // const rotateTextRef = useRef();

  useEffect(() => {
    // rotateTextRef.replace(/\S/g, "<span>$&</span>");
    // const textStr = rotateTextRef.current.textContent;
    // textStr.replace(/\S/g, "<span>$&</span>");
    // console.log("textcontent", textStr);
    console.log("text", text);
  });
  return (
    <div
      className={
        "split-text split-text--" +
        className +
        " d-flex position-relative justify-content-cneter align-items-center overflow-hidden"
      }
    >
      <Bitcoin className="icon-bitcoin" />
      <div className="split-text__wrap">
        {text.split("").map((char, index) => (
          <span
            className="split-text__item position-absolute section-sub"
            key={char}
            style={{ transform: "rotate(" + index * 11.2 + "deg)" }}
          >
            {char}
          </span>
        ))}
      </div>
    </div>
  );
};

SplitText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

SplitText.defaultProps = {
  text: null,
  className: null,
};
export default SplitText;
