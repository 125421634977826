import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Container, Button } from "react-bootstrap";
import ContactFormModal from "components/ContactFormModal";

import "components/VideoCard.scss";
import Video from "../../content/assets/video/billboard.mp4";

const VideoCard = ({
  className,
  imageAlt,
  header,
  subheader,
  extraInfo,
  videoName,
  buttonLabel,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="video-billboard">
      <video
        className="video-billboard__video"
        autoPlay={true}
        muted={true}
        loop={true}
        controls={false}
        playsInline
      >
        <source src={Video} type="video/mp4" />
      </video>
      <div className="video-billboard__content container">
        <div className="video-billboard__text-box">
          <h3 className="video-billboard__subheading display-sub">{header}</h3>
          <h2 className="video-billboard__title display-4">{subheader}</h2>
          <Button size="lg" variant="primary" className="" onClick={handleShow}>
            {buttonLabel}
          </Button>

          <ContactFormModal show={show} onHide={handleClose} buttonLabel={buttonLabel} />
          {/* {extraInfo} */}
        </div>
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  className: PropTypes.string,
  // imageFileName: PropTypes.string,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  extraInfo: PropTypes.any,
  videoName: PropTypes.string,
  buttonLabel: PropTypes.string,
};

VideoCard.defaultProps = {
  className: null,
  // imageFileName: null,
  imageAlt: null,
  header: "",
  subheader: "",
  extraInfo: null,
  videoName: "",
  buttonLabel: "",
};

export default VideoCard;
